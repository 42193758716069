import { Select, SelectItem } from '@tremor/react';
import { ExtendedDatePicker, Button } from '../../../../components';
import {
  Comparison,
  Filter,
  useGetFilterDefinitions,
} from '../../../../requests/api/traffic.requests';
import { useState } from 'react';
import { FilterTags } from './filter-tags';
import { AddFilterForm } from './add-filter-form';
import { useQuery } from '@tanstack/react-query';
import { Website } from '../../../../requests/api/website.requests';
import { RiCloseLine, RiContrastFill, RiFilterLine } from '@remixicon/react';
import { DateRange } from '../../../../requests/api/common.interfaces';

export default function SectionNavbar({
  websites,
  websiteId,
  dateRange,
  filters,
  comparison,
  showComparison,
  showFilters,
  onWebsiteIdChange,
  onDateRangeChange,
  onFiltersChange,
  onComparisonChange,
}: {
  websites: Website[];
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  comparison: Comparison | undefined;
  showComparison?: boolean;
  showFilters?: boolean;
  onWebsiteIdChange: (websiteId: string) => void;
  onDateRangeChange: (dateRange: DateRange) => void;
  onFiltersChange: (filters: Filter[]) => void;
  onComparisonChange: (
    comparison: { dateRange: DateRange; filters: Filter[] } | undefined,
  ) => void;
}) {
  const [isAddFilterFormVisible, setIsAddFilterFormVisible] = useState(false);

  const getFilterDefinitions = useGetFilterDefinitions();
  const filterDefinitions = useQuery({
    queryKey: ['filterDefinitions'],
    queryFn: () => getFilterDefinitions(),
  });

  const enableComparison = () => {
    onComparisonChange({
      dateRange: {
        type: 'shortcut',
        name: 'previousPeriod',
      },
      filters,
    });
  };

  const disableComparison = () => {
    onComparisonChange(undefined);
  };

  const addFilter = (filter: Filter) => {
    const newFilters = [...filters, filter];
    onFiltersChange(newFilters);
    setIsAddFilterFormVisible(false);
  };

  const onFilterRemoved = (filter: Filter) => {
    const newFilters = filters.filter(f => f !== filter);
    onFiltersChange(newFilters);
  };

  const addComparisonFilter = (filter: Filter) => {
    if (!comparison) {
      return;
    }

    onComparisonChange({
      ...comparison,
      filters: [...comparison.filters, filter],
    });

    setIsAddFilterFormVisible(false);
  };

  const removeComparisonFilter = (filter: Filter) => {
    if (!comparison) {
      return;
    }

    onComparisonChange({
      ...comparison,
      filters: comparison.filters.filter(f => f !== filter),
    });
  };

  const setComparisonDateRange = (dateRange: DateRange) => {
    if (!comparison) {
      return;
    }

    onComparisonChange({
      ...comparison,
      dateRange,
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col items-center gap-4 lg:flex-row">
        <Select
          className="w-full lg:w-80"
          value={websiteId}
          onValueChange={onWebsiteIdChange}
        >
          {websites.map(website => (
            <SelectItem key={website.id} value={website.id}>
              {website.name}
            </SelectItem>
          ))}
        </Select>
        <div className="hidden grow lg:block" />
        <div className="flex w-full flex-col items-center gap-4 md:flex-row lg:w-auto">
          <div className="hidden grow md:block lg:hidden" />
          <div className="flex w-full space-x-4 md:w-auto">
            {showComparison && !comparison && (
              <Button
                icon={RiContrastFill}
                className="h-9 flex-1"
                onClick={enableComparison}
              >
                Compare
              </Button>
            )}
            {showFilters && (
              <Button
                icon={RiFilterLine}
                className="h-9 flex-1"
                onClick={() => setIsAddFilterFormVisible(!isAddFilterFormVisible)}
              >
                Filter
              </Button>
            )}
          </div>
          <div className="flex w-full flex-col items-center gap-2 md:w-auto md:flex-row">
            <ExtendedDatePicker
              value={dateRange}
              onValueChange={onDateRangeChange}
              className={`${comparison ? 'select-blue' : ''} w-full md:w-64`}
            />
            {showComparison && comparison && (
              <div className="flex w-full items-center gap-2 md:w-auto">
                <span className="text-tremor-default">vs</span>
                <ExtendedDatePicker
                  className="select-comparison w-full md:w-64"
                  value={comparison.dateRange}
                  onValueChange={setComparisonDateRange}
                  isComparisonDatePicker
                />
                <Button icon={RiCloseLine} className="h-9" onClick={disableComparison} />
              </div>
            )}
          </div>
        </div>
      </div>
      {showFilters && isAddFilterFormVisible && (
        <AddFilterForm
          filterDefinitions={filterDefinitions.data || []}
          className="justify-end"
          onCancel={() => setIsAddFilterFormVisible(false)}
          onAddFilter={addFilter}
          onAddComparisonFilter={comparison && addComparisonFilter}
        />
      )}
      {showFilters && filters.length > 0 && (
        <FilterTags
          className="justify-end"
          filters={filters}
          onFilterRemoved={onFilterRemoved}
        />
      )}
      {showComparison && showFilters && comparison && comparison.filters.length > 0 && (
        <FilterTags
          className="justify-end"
          color="amber"
          filters={comparison.filters}
          onFilterRemoved={removeComparisonFilter}
        />
      )}
    </div>
  );
}

import { WebviewOwner } from 'google-oauth-webview';

export function getBrowserName(owner: WebviewOwner): string {
  switch (owner) {
    case 'LinkedIn':
      return "LinkedIn's in-app browser";
    case 'Facebook':
      return "Facebook's in-app browser";
    case 'Instagram':
      return "Instagram's in-app browser";
    default:
      return 'an in-app browser';
  }
}

export function getProhibitionMessage(
  type: 'sign-in' | 'sign-up' | 'connect-to-ga4',
): string {
  switch (type) {
    case 'sign-in':
      return 'Logging in with Google in this browser is prohibited for security reasons.';
    case 'sign-up':
      return 'Signing up with Google in this browser is prohibited for security reasons.';
    case 'connect-to-ga4':
      return 'Connecting to Google Analytics 4 in this browser is prohibited for security reasons.';
    default:
      throw new Error(`Invalid type: ${type}`);
  }
}

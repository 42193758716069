export default function SkeletonLoader({
  className,
  hiddenText,
  hiddenText2,
}: {
  className?: string;
  hiddenText?: string;
  hiddenText2?: string;
}) {
  return (
    <div className={`${className || ''} animate-pulse rounded-md bg-loading`}>
      {hiddenText && (
        <span className="invisible">
          {hiddenText}
          {hiddenText2 && (
            <>
              <br />
              {hiddenText2}
            </>
          )}
        </span>
      )}
    </div>
  );
}

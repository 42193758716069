import {
  Callout,
  Card,
  LineChart,
  Select,
  SelectItem,
  Tab,
  TabGroup,
  TabList,
} from '@tremor/react';
import { formatPageViewsPerVisit, useTrafficBlock } from '../common';
import {
  formatNumber,
  formatPercentage,
  formatSecondsInMinutes,
} from '../../../../utils';
import { SkeletonLoader, CardTitle } from '../../../../components';
import { useState } from 'react';
import { Comparison, Filter } from '../../../../requests/api/traffic.requests';
import { formatTimeSeries } from './time-series-card.utils';
import { DateRange } from '../../../../requests/api/common.interfaces';

export type TimeSeriesBlockName =
  | 'visits'
  | 'pageViews'
  | 'pageViewsPerVisit'
  | 'engagementRate'
  | 'visitDuration'
  | 'visitors';

const tabs: {
  id: TimeSeriesBlockName;
  label: string;
  valueFormatter: (value: number) => string;
}[] = [
  { id: 'visits', label: 'Visits', valueFormatter: formatNumber },
  { id: 'pageViews', label: 'Pageviews', valueFormatter: formatNumber },
  {
    id: 'pageViewsPerVisit',
    label: 'Views per visit',
    valueFormatter: formatPageViewsPerVisit,
  },
  {
    id: 'engagementRate',
    label: 'Engagement rate',
    valueFormatter: formatPercentage,
  },
  {
    id: 'visitDuration',
    label: 'Visit duration',
    valueFormatter: formatSecondsInMinutes,
  },
  { id: 'visitors', label: 'Visitors', valueFormatter: formatNumber },
];

interface TimeSeriesCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  comparison: Comparison | undefined;
}

function getComparisonLabel(label: string) {
  return `${label} (comparison)`;
}

export default function TimeSeriesCard({
  websiteId,
  dateRange,
  filters,
  comparison,
}: TimeSeriesCardProps) {
  // const [areMoreOptionsVisible, setAreMoreOptionsVisible] = useState(false);

  const [blockName, setBlockName] = useState<TimeSeriesBlockName>('visits');

  const block = useTrafficBlock({
    queryKey: 'timeSeriesBlock',
    blockName,
    websiteId,
    dateRange,
    filters,
    comparison,
  });

  const currentTab = tabs.find(({ id }) => id === blockName)!;

  return (
    <Card>
      <div className="flex items-center">
        <CardTitle title="Overview" />
        <div className="grow"></div>
        <TabGroup
          className="hidden w-min xl:block"
          index={tabs.findIndex(({ id }) => id === blockName)}
          onIndexChange={index => setBlockName(tabs[index].id)}
        >
          <TabList variant="solid">
            {tabs.map(tab => (
              <Tab key={tab.id}>{tab.label}</Tab>
            ))}
          </TabList>
        </TabGroup>
        <Select
          className="max-w-44 xl:hidden"
          value={blockName}
          onValueChange={value => setBlockName(value as TimeSeriesBlockName)}
        >
          {tabs.map(tab => (
            <SelectItem key={tab.id} value={tab.id}>
              {tab.label}
            </SelectItem>
          ))}
        </Select>
      </div>
      {(block.isPending || block.isRefetching) && (
        <>
          <SkeletonLoader className="mt-6 hidden h-96 sm:block" />
          <SkeletonLoader className="mt-6 h-72 sm:hidden" />
        </>
      )}
      {block.isError && !block.isRefetching && (
        <>
          <div className="mt-6 hidden h-96 sm:block">
            <Callout title="An error has occurred" color="red">
              An error occurred while retrieving the data. Try refreshing the page.
            </Callout>
          </div>
          <div className="mt-6 h-72 sm:hidden">
            <Callout title="An error has occurred" color="red">
              An error occurred while retrieving the data. Try refreshing the page.
            </Callout>
          </div>
        </>
      )}
      {block.isSuccess && !block.isRefetching && (
        <>
          <LineChart
            connectNulls
            data={formatTimeSeries({
              timeSeries: block.data.timeSeries,
              label: currentTab.label,
              comparisonLabel: getComparisonLabel(currentTab.label),
              aggregationStep: block.data.aggregationStep,
            })}
            index="formattedDate"
            showLegend={false}
            categories={[currentTab.label, getComparisonLabel(currentTab.label)]}
            colors={['blue', 'amber']}
            valueFormatter={currentTab.valueFormatter}
            yAxisWidth={blockName === 'visitDuration' ? 70 : undefined}
            className="mt-6 hidden h-96 sm:block"
          />
          <LineChart
            connectNulls
            data={formatTimeSeries({
              timeSeries: block.data.timeSeries,
              label: currentTab.label,
              comparisonLabel: getComparisonLabel(currentTab.label),
              aggregationStep: block.data.aggregationStep,
            })}
            index="formattedDate"
            showLegend={false}
            categories={[currentTab.label, getComparisonLabel(currentTab.label)]}
            colors={['blue', 'amber']}
            valueFormatter={currentTab.valueFormatter}
            showYAxis={false}
            startEndOnly={true}
            className="mt-6 h-72 sm:hidden"
          />
        </>
      )}

      {/* {areMoreOptionsVisible ? (
        <div>
          <div className="mt-6 flex items-center space-x-4">
            <div className="grow"></div>
            <p className="text-tremor-default text-tremor-content">Aggregate by</p>
            <TabGroup className="w-min">
              <TabList variant="solid" defaultValue="1">
                <Tab value="1">Hour</Tab>
                <Tab value="2">Day</Tab>
                <Tab value="3">Week</Tab>
                <Tab value="4">Month</Tab>
              </TabList>
            </TabGroup>
          </div>
        </div>
      ) : (
        <div className="mt-6 flex space-x-6">
          <div className="grow"></div>
          <Button variant="light" onClick={() => setAreMoreOptionsVisible(true)}>
            More options
          </Button>
          <div className="grow"></div>
        </div>
      )} */}
    </Card>
  );
}

import { formatNumber } from '../utils';
import ComparisonBadge from './ComparisonBadge';
import SkeletonLoader from './SkeletonLoader';

export default function NumberValue({
  className,
  values: { value, comparisonValue },
  isLoading,
  showComparisonBadgeUponComparison,
  valueFormatter = formatNumber,
}: {
  className?: string;
  values: {
    value: number | null;
    comparisonValue?: number | null;
  };
  isLoading?: boolean;
  showComparisonBadgeUponComparison?: boolean;
  valueFormatter?: (value: number) => string;
}) {
  const formattedValue = value === null ? '-' : valueFormatter(value);

  if (comparisonValue === undefined) {
    if (isLoading) {
      return <SkeletonLoader hiddenText={formattedValue} className="inline-block" />;
    }

    return <>{formattedValue}</>;
  }

  const formattedComparisonValue =
    comparisonValue === null ? '-' : valueFormatter(comparisonValue);

  return (
    <div className={`flex justify-center space-x-3 ${className || ''}`}>
      {showComparisonBadgeUponComparison && (
        <div className="flex items-center justify-end">
          <ComparisonBadge values={{ value, comparisonValue }} isLoading={isLoading} />
        </div>
      )}
      <div>
        {isLoading ? (
          <SkeletonLoader
            className="inline-block"
            hiddenText={formattedValue}
            hiddenText2={formattedComparisonValue}
          />
        ) : (
          <>
            <span className="text-blue-500">{formattedValue}</span>
            <br />
            <span className="text-comparison-500">{formattedComparisonValue}</span>
          </>
        )}
      </div>
    </div>
  );
}

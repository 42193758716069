import { Select, SelectItem } from '@tremor/react';
import { useEvents } from '../common/use-conversion-block.hook';
import { DateRange } from '../../../../requests/api/common.interfaces';

export default function ConversionFilterBar({
  websiteId,
  dateRange,
  eventName,
  onEventNameChange,
}: {
  websiteId: string;
  dateRange: DateRange;
  eventName: string | undefined;
  onEventNameChange: (eventName: string) => void;
}) {
  const events = useEvents({
    websiteId,
    dateRange,
    queryKey: 'events',
  });

  return (
    <div className="flex justify-around">
      <Select className="md:max-w-sm" value={eventName} onValueChange={onEventNameChange}>
        {events.isSuccess &&
          events.data?.items.map(event => (
            <SelectItem key={event.name} value={event.name}>
              {event.name}
            </SelectItem>
          ))}
      </Select>
    </div>
  );
}

import { SectionNavbar } from '../common';
import { useOutletContext } from 'react-router-dom';
import { SectionContext } from '../section-container';
import { KeyMetricConversionCard } from './key-metric-conversion-card';
import { ConversionCard } from './conversion-card';
import { ConversionFilterBar } from './conversion-filter-bar';
import { useState } from 'react';
import { Comparison, Filter } from '../../../requests/api/traffic.requests';

export default function ConversionSection() {
  /* Navbar */
  const { websiteId, websites, dateRange, setWebsiteId, setDateRange } =
    useOutletContext<SectionContext>();
  const [filters, setFilters] = useState<Filter[]>([]);
  const [comparison, setComparison] = useState<Comparison | undefined>(undefined);

  const [eventName, setEventName] = useState<string | undefined>(undefined);

  return (
    <div className="space-y-6">
      <SectionNavbar
        websites={websites}
        websiteId={websiteId}
        dateRange={dateRange}
        filters={filters}
        comparison={comparison}
        onWebsiteIdChange={setWebsiteId}
        onDateRangeChange={setDateRange}
        onFiltersChange={setFilters}
        onComparisonChange={setComparison}
      />
      <ConversionFilterBar
        websiteId={websiteId}
        dateRange={dateRange}
        eventName={eventName}
        onEventNameChange={setEventName}
      />
      {eventName && (
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-4 lg:col-span-1">
            <KeyMetricConversionCard
              websiteId={websiteId}
              dateRange={dateRange}
              eventName={eventName}
            />
          </div>
          <div className="col-span-4 lg:col-span-3">
            <ConversionCard
              websiteId={websiteId}
              dateRange={dateRange}
              eventName={eventName}
            />
          </div>
        </div>
      )}
    </div>
  );
}

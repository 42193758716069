import { useMakeAuthenticatedGetRequest } from '../requests.hooks';
import { DateRange } from './common.interfaces';

type CampaignConversionBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

type ChannelGroupConversionBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

type KeyMetricConversionBlock = {
  totalConvertedVisitors: {
    value: number | null;
    comparisonValue?: number | null;
  };
};

type SourceConversionBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

type EventBlock = {
  items: {
    name: string;
  }[];
};

type LandingPageConversionBlock = {
  items: {
    name: string;
    totalConvertedVisitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

export function useGetEvents(): ({
  websiteId,
  dateRange,
  currentDate,
}: {
  websiteId: string;
  dateRange: DateRange;
  currentDate: string;
}) => Promise<EventBlock> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async ({ websiteId, dateRange, currentDate }) => {
    const searchParams = new URLSearchParams();

    searchParams.append('websiteId', websiteId);
    searchParams.append('currentDate', currentDate);
    searchParams.append('dateRange', JSON.stringify(dateRange));

    const { data } = await makeAuthenticatedGetRequest(
      `/api/conversion/events?${searchParams.toString()}`,
    );
    return data;
  };
}

function createUseGetConversionBlock<Block>({
  endpoint,
}: {
  endpoint: string;
}): () => ({
  websiteId,
  dateRange,
  currentDate,
  eventName,
}: {
  websiteId: string;
  dateRange: DateRange;
  currentDate: string;
  eventName: string;
}) => Promise<Block> {
  return () => {
    const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

    return async ({ websiteId, dateRange, currentDate, eventName }) => {
      const searchParams = new URLSearchParams();

      searchParams.append('websiteId', websiteId);
      searchParams.append('currentDate', currentDate);
      searchParams.append('dateRange', JSON.stringify(dateRange));
      searchParams.append('eventName', eventName);

      const { data } = await makeAuthenticatedGetRequest(
        `/api/conversion/blocks/${endpoint}?${searchParams.toString()}`,
      );
      return data;
    };
  };
}

export const useGetCampaignConversionBlock =
  createUseGetConversionBlock<CampaignConversionBlock>({
    endpoint: 'campaigns',
  });

export const useGetChannelGroupConversionBlock =
  createUseGetConversionBlock<ChannelGroupConversionBlock>({
    endpoint: 'channel-groups',
  });

export const useGetKeyMetricConversionBlock =
  createUseGetConversionBlock<KeyMetricConversionBlock>({
    endpoint: 'key-metrics',
  });

export const useGetConversionLandingPageBlock =
  createUseGetConversionBlock<LandingPageConversionBlock>({
    endpoint: 'landing-pages',
  });

export const useGetSourceConversionBlock =
  createUseGetConversionBlock<SourceConversionBlock>({
    endpoint: 'sources',
  });

import { TabGroup, TabList, Tab } from '@tremor/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isConversionEnabled } from '../../../configuration';

const tabs = [
  {
    label: 'Traffic',
    path: 'traffic',
  },
  // {
  //   label: 'Ranking',
  //   path: 'ranking',
  // },
  // {
  //   label: 'Conversion',
  //   path: 'conversion',
  // },
  // {
  //   label: 'Custom reports',
  //   path: 'custom-reports',
  // },
  // {
  //   label: 'Configuration',
  //   path: 'configuration',
  // },
];

if (isConversionEnabled()) {
  tabs.push({
    label: 'Conversion',
    path: 'conversion',
  });
}

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  // Hack: this will only works if there is no subroutes
  const activeIndex = tabs.findIndex(tab => location.pathname.endsWith(tab.path));

  return (
    <nav>
      <TabGroup index={activeIndex} onIndexChange={index => navigate(tabs[index].path)}>
        <TabList variant="line">
          {tabs.map((tab, index) => (
            <Tab key={tab.path} value={index}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
      </TabGroup>
    </nav>
  );
}

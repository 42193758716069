import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getNowDate } from '../../../../utils';
import { DateRange } from '../../../../requests/api/common.interfaces';
import {
  useGetCampaignConversionBlock,
  useGetChannelGroupConversionBlock,
  useGetKeyMetricConversionBlock,
  useGetConversionLandingPageBlock,
  useGetEvents,
  useGetSourceConversionBlock,
} from '../../../../requests/api/conversion.requests';

export function useEvents({
  websiteId,
  queryKey,
  dateRange,
  onInitialLoadingComplete,
}: {
  websiteId: string;
  queryKey: string;
  dateRange: DateRange;
  onInitialLoadingComplete?: () => void;
}) {
  const getEvents = useGetEvents();
  const block = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      const currentDate = getNowDate();

      return getEvents({
        websiteId,
        currentDate,
        dateRange,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId, dateRange]);

  useEffect(() => {
    if (!block.isLoading || block.isLoadingError) {
      onInitialLoadingComplete && onInitialLoadingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.isLoading, block.isLoadingError]);

  return block;
}

export function useKeyMetricConversionBlock({
  websiteId,
  queryKey,
  dateRange,
  eventName,
  onInitialLoadingComplete,
}: {
  websiteId: string;
  queryKey: string;
  dateRange: DateRange;
  eventName: string;
  onInitialLoadingComplete?: () => void;
}) {
  const getKeyMetricConversionBlock = useGetKeyMetricConversionBlock();
  const block = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      const currentDate = getNowDate();

      return getKeyMetricConversionBlock({
        websiteId,
        currentDate,
        dateRange,
        eventName,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId, dateRange, eventName]);

  useEffect(() => {
    if (!block.isLoading || block.isLoadingError) {
      onInitialLoadingComplete && onInitialLoadingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.isLoading, block.isLoadingError]);

  return block;
}

export function useSourceConversionBlock({
  websiteId,
  queryKey,
  dateRange,
  eventName,
  blockName,
  onInitialLoadingComplete,
}: {
  websiteId: string;
  queryKey: string;
  dateRange: DateRange;
  eventName: string;
  blockName: 'sources' | 'landingPages' | 'campaigns' | 'channelGroups';
  onInitialLoadingComplete?: () => void;
}) {
  const getSourceConversionBlock = useGetSourceConversionBlock();
  const getLandingPageConversionBlock = useGetConversionLandingPageBlock();
  const getCampaignConversionBlock = useGetCampaignConversionBlock();
  const getChannelGroupConversionBlock = useGetChannelGroupConversionBlock();

  const block = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      const currentDate = getNowDate();

      if (blockName === 'landingPages') {
        return getLandingPageConversionBlock({
          websiteId,
          currentDate,
          dateRange,
          eventName,
        });
      }

      if (blockName === 'campaigns') {
        return getCampaignConversionBlock({
          websiteId,
          currentDate,
          dateRange,
          eventName,
        });
      }

      if (blockName === 'channelGroups') {
        return getChannelGroupConversionBlock({
          websiteId,
          currentDate,
          dateRange,
          eventName,
        });
      }

      return getSourceConversionBlock({
        websiteId,
        currentDate,
        dateRange,
        eventName,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId, dateRange, eventName, blockName]);

  useEffect(() => {
    if (!block.isLoading || block.isLoadingError) {
      onInitialLoadingComplete && onInitialLoadingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.isLoading, block.isLoadingError]);

  return block;
}

import { Callout, Card } from '@tremor/react';
import { CardTitle, NumberValue, SkeletonLoader } from '../../../../components';
import { DateRange } from '../../../../requests/api/common.interfaces';
import { useKeyMetricConversionBlock } from '../common';

interface KeyMetricConversionCardProps {
  websiteId: string;
  dateRange: DateRange;
  eventName: string;
}

export default function KeyMetricConversionCard({
  websiteId,
  dateRange,
  eventName,
}: KeyMetricConversionCardProps) {
  const block = useKeyMetricConversionBlock({
    websiteId,
    dateRange,
    eventName,
    queryKey: 'keyMetricConversionBlock',
  });

  return (
    <Card>
      <CardTitle title="Overview" />
      {block.isPending && <SkeletonLoader className="mt-4 h-16" />}
      {block.isError && (
        <Callout title="An error has occurred" color="red" className="mt-4">
          An error occurred while retrieving the data. Try refreshing the page.
        </Callout>
      )}
      {block.isSuccess && (
        <div className="mt-4 flex justify-around gap-6 lg:flex-col">
          <div className="space-y-1">
            <div className="text-center text-tremor-metric text-tremor-brand">
              <NumberValue
                values={block.data.totalConvertedVisitors}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </div>
            <div className="text-center text-tremor-label text-tremor-content">
              Converted visitors
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}

import { AcquisitionBlockName } from './AcquisitionCard';

export function getFilterKeyFromBlockName(blockName: AcquisitionBlockName): string {
  switch (blockName) {
    case 'sources':
      return 'sourceName';
    case 'mediums':
      return 'mediumName';
    case 'campaigns':
      return 'campaignName';
    default:
      throw new Error('Invalid block name');
  }
}
